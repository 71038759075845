import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../components/mdrender";
import * as md from "../components/mdrender.module.sass";
import TitleBlock from "../components/title";

import "./support-help@gen.scss";

const GeneratedSupportHelp = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          FAQ/ガイド/サポート - PagerDuty正規代理店 - 株式会社Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyに関するFAQ/ガイド/サポート情報を集めています。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyに関するFAQ/ガイド/サポート情報を集めています。"
        />

        <meta
          property="og:title"
          content="FAQ/ガイド/サポート - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDutyに関するFAQ/ガイド/サポート情報を集めています。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/support-help/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/support-help/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Support"
        textGreen="サポート"
        overview=""
      />

      <div className="supportHelpWrapper">
        <div className="supportHelpContainer">
          <div className="center-1 mb-[100px] mt-0">
            <h2 className="center-title">
              PagerDutyに関するFAQ/ガイド/サポート情報を集めています。
            </h2>
          </div>
          <div className="three-col-media-contents mb-[100px]">
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Support/support_help_img_1_1a968a5dfd.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672988891964&tr=w-320,q-100,f-webp"
                  alt="FAQ"
                />
              </div>
              <p className="title">
                <a href="/faq/">FAQ</a>
              </p>
              <p className="para">
                ライセンス、機能、設定について、当社によくいただく質問に回答しています
              </p>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Support/support_help_img_2_957ae97e45.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672988891924&tr=w-320,q-100,f-webp"
                  alt="インテグレーションガイド"
                />
              </div>
              <p className="title">
                <a href="/docs-guides/">インテグレーションガイド</a>
              </p>
              <p className="para">
                200を超える他社サービスとのインテグレーションの仕方を解説した米国PagerDutyのガイドを和訳したものを紹介しています。
              </p>
            </div>
            <div className="card">
              <div className="media-content">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Support/support_help_img_3_47445f8943.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672988891906&tr=w-320,q-100,f-webp"
                  alt="PagerDuty DigitalStacks 日本語サポートサイト"
                />
              </div>
              <p className="title">
                <a href="https://pagerduty-digitalstacks.zendesk.com/hc/ja">
                  PagerDuty DigitalStacks
                  <br />
                  日本語サポートサイト
                </a>
              </p>
              <p className="para mt11">
                PagerDutyの使い方や技術情報を日本語で掲載しているサイトです。弊社技術サポートへのお問い合わせも受け付けています。ご利用いただけるのは、弊社を通じてPagerDutyを契約されたユーザーの方です。ユーザー登録の方法は貴社の管理者または弊社担当営業までお問い合わせください。
              </p>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedSupportHelp;
